import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loading from "./Reusable_Components/ScrollTop/Loading";

// Lazy loading other sections
const About = lazy(() => import("./About_section/About"));
const Blogs = lazy(() => import("./Blogs_section/Blogs"));
const Demo = lazy(() => import("./Demo_section/Demo"));
const Faqs = lazy(() => import("./Faqs_section/Faqs"));
const Home = lazy(() => import("./Home_section/Home"));
const LeConnect = lazy(() => import("./LeConnect_section/LeConnect"));
const ProductServices = lazy(() => import("./Product_Services_section/ProductServices"));
const TradeFinance = lazy(() => import("./Trade_Finance/TradeFinance"));

// Media components mapping
const mediaComponents = [
  { path: "/opportunities-and-challenges-in-global-trade-market", component: lazy(() => import("./Blogs_section/SubMedia/MediaOne/MediaOne")) },
  { path: "/impact-of-cbdc-on-cross-border-transactions", component: lazy(() => import("./Blogs_section/SubMedia/MediaTwo/MediaTwo")) },
  { path: "/latest-trends-in-cross-border-finance", component: lazy(() => import("./Blogs_section/SubMedia/MediaThree/MediaThree")) },
  { path: "/international-trade-growth-factors-for-msmes", component: lazy(() => import("./Blogs_section/SubMedia/MediaFour/MediaFour")) },
  { path: "/role-of-ftas-in-trade-economy", component: lazy(() => import("./Blogs_section/SubMedia/MediaFive/MediaFive")) },
  { path: "/msme-global-expansion-pathways", component: lazy(() => import("./Blogs_section/SubMedia/MediaSix/MediaSix")) },
  { path: "/fintech-cross-border-remittance-revolution", component: lazy(() => import("./Blogs_section/SubMedia/MediaSeven/MediaSeven")) },
  { path: "/cross-border-vs-domestic-payments-complexity", component: lazy(() => import("./Blogs_section/SubMedia/Media8/Media8")) },
  { path: "/understanding-swift-in-global-finance", component: lazy(() => import("./Blogs_section/SubMedia/Media9/Media9")) },
  { path: "/charges-and-fees-for-international-payments", component: lazy(() => import("./Blogs_section/SubMedia/Media10/Media10")) },
  { path: "/purpose-codes-in-international-payments", component: lazy(() => import("./Blogs_section/SubMedia/Media11/Media11")) },
  { path: "/compliance-vs-alliance-b2b-remittance-battle", component: lazy(() => import("./Blogs_section/SubMedia/Media12/Media12")) },
  { path: "/india-exports-2022-2023-insights", component: lazy(() => import("./Blogs_section/SubMedia/Media13/Media13")) },
  { path: "/building-company-vs-running-marathon-insights", component: lazy(() => import("./Blogs_section/SubMedia/Media14/Media14")) },
  { path: "/cbdc-impact-on-retail-and-wholesale-cross-border-challenges", component: lazy(() => import("./Blogs_section/SubMedia/Media15/Media15")) },
  { path: "/understanding-nostro-vostro-accounts-in-global-banking", component: lazy(() => import("./Blogs_section/SubMedia/Media16/Media16")) },
  { path: "/essential-documents-for-cross-border-trade-guide", component: lazy(() => import("./Blogs_section/SubMedia/Media17/Media17")) },
  { path: "/role-of-exchange-houses-middle-east-remittances-to-india", component: lazy(() => import("./Blogs_section/SubMedia/Media18/Media18")) },
  { path: "/what-is-ebrc-how-to-obtain-it-guide", component: lazy(() => import("./Blogs_section/SubMedia/Media19/Media19")) },
  { path: "/complete-guide-about-utr-number", component: lazy(() => import("./Blogs_section/SubMedia/Media20/Media20")) },
  { path: "/learn-about-foreign-inward-remittance-certificate", component: lazy(() => import("./Blogs_section/SubMedia/Media21/Media21")) },
  { path: "/guide-about-iec-registration-process", component: lazy(() => import("./Blogs_section/SubMedia/Media22/Media22")) },
  { path: "/fema-guidelines", component: lazy(() => import("./Blogs_section/SubMedia/Media23/Media23")) },
  { path: "/what-is-trade-finance", component: lazy(() => import("./Blogs_section/SubMedia/Media24/Media24")) },
  { path: "/guide-on-export-declaration-form", component: lazy(() => import("./Blogs_section/SubMedia/Media25/Media25")) },
  { path: "/what-is-bill-discounting", component: lazy(() => import("./Blogs_section/SubMedia/Media26/Media26")) },
  { path: "/rbi-guidelines-for-inward-remittance", component: lazy(() => import("./Blogs_section/SubMedia/Media27/Media27")) },
  { path: "/types-of-export-invoices", component: lazy(() => import("./Blogs_section/SubMedia/Media28/Media28")) },
  { path: "/what-is-a-working-capital-loan", component: lazy(() => import("./Blogs_section/SubMedia/Media29/Media29")) },
  { path: "/payment-gateway-vs-payment-aggregator", component: lazy(() => import("./Blogs_section/SubMedia/Media30/Media30")) },
  { path: "/export-data-processing-and-monitoringsystem", component: lazy(() => import("./Blogs_section/SubMedia/Media31/Media31")) },
  { path: "/types-of-cross-border-payments", component: lazy(() => import("./Blogs_section/SubMedia/Media32/Media32")) },
  { path: "/import-data-processing-and-monitoring-system", component: lazy(() => import("./Blogs_section/SubMedia/Media33/Media33")) },
  { path: "/bill-discounting-vs-bill-purchase", component: lazy(() => import("./Blogs_section/SubMedia/Media34/Media34")) },
];

ReactGA.initialize("G-VD04WS1MZE");

// lead create code dont remove 
const Scroll = () => {
  const { pathname } = useLocation();

  const [queryParams, setQueryParams] = useState(new URLSearchParams(window.location.search));

  useEffect(() => {
    setQueryParams(new URLSearchParams(window.location.search));
  }, []);
  if (queryParams.get("utm_source")) {
    sessionStorage.setItem("from", queryParams.get("utm_source"));
  } else {
    sessionStorage.setItem("from", "Website");
  }
  if (queryParams.get("utm_medium")) {
    sessionStorage.setItem("medium", queryParams.get("utm_medium"));
  } else {
    sessionStorage.setItem("medium", "Website");
  }
  if (queryParams.get("utm_campaign")) {
    sessionStorage.setItem("campaign", queryParams.get("utm_campaign"));
  } else {
    sessionStorage.setItem("campaign", "Website");
  }
  if (queryParams.get("fbclid")) {
    sessionStorage.setItem("fbclid", queryParams.get("fbclid"));
  }

  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <RedirectToWWW />
        <Scroll />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/leconnect" element={<LeConnect />} />
          <Route path="/accept-international-payments-for-businesses" element={<ProductServices />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/trade-finance-solution" element={<TradeFinance />} />

          <Route path="*" element={<Navigate to="/" />} />

          {mediaComponents.map((media, index) => (
            <Route key={index} path={media.path} element={<media.component />} />
          ))}

          {redirectRoutes.map((route) => (
            <Route path={route.path} element={<Navigate to={route.to} />} />
          ))}

        </Routes>
      </Suspense>
    </>
  );
};

export default App;

const RedirectToWWW = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname === 'leremitt.com') {
      const newUrl = `https://www.leremitt.com${location.pathname}${location.search}${location.hash}`;
      window.location.replace(newUrl);
    }
  }, [location]);

  return null;
};


const redirectRoutes = [
  { path: '/productservices', to: '/accept-international-payments-for-businesses' },
  { path: '/MediaTwo', to: '/impact-of-cbdc-on-cross-border-transactions' },
  { path: '/MediaFive', to: '/role-of-ftas-in-trade-economy' },
];
